import React from "react"
import Image from "next/image"
import { useRouter } from "next/router"
import { Trans, useTranslation } from "next-i18next"
import { useMediaQuery } from "react-responsive"
import tw from "twin.macro"

import { When } from "@components/If"
import Container from "@components/v2/Container"
import { Button } from "@components/v3/Button"
import useAuth from "@hooks/useAuth"
import Space from "@layouts/Space"

import HeroImage from "../images/mockup-hero.webp"

const StyledContainer = tw(Container)`
    flex xl:flex-row flex-col items-center xl:justify-between justify-center
    xl:py-10 py-8
    xl:gap-0 gap-4
`
const WrapperLeft = tw(Space)`
    xl:!gap-8 !gap-4
    xl:items-start items-center
    xl:text-left text-center
    !w-full
`

const WrapperText = tw(Space)`
    xl:!gap-3
    xl:items-start items-center
    !w-full
   
`

const WrapperCTA = tw(Space)`
    !gap-4 xl:flex-row
    !w-full
`

const Title = tw.h1`
    xl:heading-1 heading-5
    text-bold dark:text-dark-bold
   
`

const Description = tw.span`
    xl:paragraph-1 paragraph-3
    text-main dark:text-dark-main
    
`

const Hero = () => {
    const { t } = useTranslation("home")
    const isDesktop = useMediaQuery({ minWidth: 1280 })
    const router = useRouter()
    const {
        auth: { isLoggedIn }
    } = useAuth()

    return (
        <section>
            <StyledContainer className='reku-new'>
                <WrapperLeft direction='vertical'>
                    <WrapperText direction='vertical'>
                        <Title>
                            <Trans i18nKey='us_stock.hero.title'>{t("us_stock.hero.title")}</Trans>
                        </Title>
                        <Description>
                            <Trans i18nKey='us_stock.hero.desc'>{t("us_stock.hero.desc")}</Trans>
                        </Description>
                    </WrapperText>
                    <WrapperCTA direction='vertical'>
                        <When condition={!isLoggedIn}>
                            <Button
                                size={isDesktop ? "xl" : "lg"}
                                block={!isDesktop}
                                width={isDesktop ? 127 : undefined}
                                onClick={() => router.push("/uss/register")}
                            >
                                {t("common:sign_up")}
                            </Button>
                        </When>
                        <Button
                            size={isDesktop ? "xl" : "lg"}
                            block={!isDesktop}
                            outline
                            variant='gray'
                            onClick={() => router.push("/markets")}
                        >
                            {t("us_stock.hero.cta.explore")}
                        </Button>
                    </WrapperCTA>
                </WrapperLeft>
                <div className='w-full'>
                    <Image src={HeroImage.src} width={600} height={480} layout='responsive' alt='hero-homapage' />
                </div>
            </StyledContainer>
        </section>
    )
}

export default Hero
